<template>
    <div ref="scrollTarget"></div>
    <MainNav />

    <section class="laptop-section">
        <section class="background-container" style="margin-top: 50px; padding-bottom: 50px; height: 600px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">


            <div class="container-fluid"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%;">
                <div class="row gx-5" style="margin-left: 120px; margin-top: 150px;">
                    <div class="col-md-6">
                        <p style="color: white; font-weight: bold; font-size: xx-large;">{{ translate('technologie_header') }}</p>
                        <br>
                        <p style="color: white; font-weight: 400; font-size: medium;">{{ translate('technologie_sheader') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 70px; padding-top: 0; padding-bottom: 0;">
            <div class="container-fluid">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6" style="text-align: right; margin-right: -15px;">
                        <img src="../assets/img/technologie1.jpg">
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
                        <div style="text-align: left; padding: 30px;">
                            <p style="font-size: 2.2em; color: black; font-weight: bold;">{{translate('technologie1_title') }}</p>
                            <br>
                            <br>
                            <router-link to="/technologie1">
                            <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img style="margin-left: 15px;" src="../assets/img/right_row.svg"> </button>
                        </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <div class="container-fluid">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7; border-top-left-radius: 10px; border-bottom-left-radius: 10px;">
                        <div style="text-align: right; padding: 30px;">
                            <p style="font-size: 2.2em; color: black; font-weight: bold; text-align: left;">{{translate('technologie2_title') }}</p>
                            <br>
                            <br>
                            <router-link to="/technologie2">
                            <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img style="margin-left: 15px;" src="../assets/img/right_row.svg"> </button>
                        </router-link>
                        </div>
                    </div>
                    <div class="col-md-6" style="text-align: left; margin-left: -15px;">
                        <img src="../assets/img/technologie2.jpg">
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <div class="container-fluid">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6" style="text-align: right; margin-right: -15px;">
                        <img src="../assets/img/technologie3.jpg">
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
                        <div style="text-align: left; padding: 30px;">
                            <p style="font-size: 2.2em; color: black; font-weight: bold;">{{translate('technologie3_title') }}</p>
                            <br>
                            <br>
                            <router-link to="/technologie3">
                            <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img style="margin-left: 15px;" src="../assets/img/right_row.svg"> </button>
                        </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 120px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-8 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; padding: 30px; background-color: #FCEEF0;">
                            <div style="text-align: left; flex-grow: 1; font-size: large; color: #CF0020;">
                                <span>{{ translate('technologie_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <div class="laptop-header" style="margin-top: 60px;">
            <header class="position-relative">
                <!-- Image -->
                <transition name="image-fade" mode="out-in">
                    <img src="../assets/img/technologie_header_mobile.jpg" class="d-block w-100" alt="Background Image">
                </transition>

                <!-- Navbar -->
                <MainNav />

            </header>
        </div>

        <section style="padding-top: 0px; padding-bottom: 40px; background-color: #D0D0D0; margin-top: -20px;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12">
                        <p style="color: #CF0020; font-size: x-large; font-weight: bold;">{{ translate('technologie_header')}}</p>
                        <br>
                        <p style="color: black; font-size: medium;">{{ translate('technologie_sheader') }} </p>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 30px; padding-top: 20px; padding-bottom: 20px;">
            <div class="container">
                <div class="row text-center">
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/technologie1_mobile.jpg" style="width: 100%;">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: black; font-weight: bold; font-size: 1.3em;">
                                    {{ translate('technologie1_title') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/technologie1">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex" style="margin-top: 20px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/technologie2_mobile.jpg" style="width: 100%;">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: black; font-weight: bold; font-size: 1.3em;">
                                    {{ translate('technologie2_title') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/technologie2">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex" style="margin-top: 20px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/technologie3_mobile.jpg" style="width: 100%;">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: black; font-weight: bold; font-size: 1.3em;">
                                    {{ translate('technologie3_title') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/technologie3">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 10px; padding-top: 10px; padding-bottom: 80px;">
            <div class="container" style="margin-top: 20px;">
                <div class="row text-center justify-content-center">
                    <div class="col-11 d-flex">
                        <div class="d-flex flex-column border"
                            style="border-radius: 10px; background-color: #FCEEF0; padding: 30px; ">
                            <div style="text-align: center; flex-grow: 1; font-size: large; color: #CF0020;">
                                <span>{{ translate('technologie_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="ipad-section">
        <div class="laptop-header" style="margin-top: 50px;">
            <header class="position-relative">
                <!-- Image -->
                <transition name="image-fade" mode="out-in">
                    <img src="../assets/img/technologie_header.jpg" class="d-block w-100" alt="Background Image">
                </transition>

                <!-- Navbar -->
                <MainNav />

                <div class="position-absolute bottom-0 text-white mobile-prices"
                    style="width: 70%; margin-bottom: 15px; padding-left: 20px; padding-right: 20px;">
                    <p style="font-weight: bold; font-size: x-large; color: white;">{{ translate('technologie_header') }}</p>
                </div>
            </header>
        </div>

        <section style="padding-top: 20px; padding-bottom: 20px;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12" style="margin-top: 0px;">
                        <p style="color: black; font-size: medium;">{{ translate('technologie_sheader') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 40px; padding-top: 0; padding-bottom: 0;">
            <div class="container-fluid">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6" style="text-align: right; margin-right: -15px;">
                        <img src="../assets/img/technologie1.jpg">
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
                        <div style="text-align: left; padding: 30px;">
                            <p style="font-size: xx-large; color: black; font-weight: bold;">{{translate('technologie1_title') }}</p>
                            <br>
                            <br>
                            <router-link to="/technologie1">
                            <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img style="margin-left: 15px;" src="../assets/img/right_row.svg"> </button>
                        </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 30px; padding-top: 0; padding-bottom: 0;">
            <div class="container-fluid">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7; border-top-left-radius: 10px; border-bottom-left-radius: 10px;">
                        <div style="text-align: right; padding: 30px;">
                            <p style="font-size: xx-large; color: black; font-weight: bold; text-align: left;">{{translate('technologie2_title') }}</p>
                            <br>
                            <br>
                            <router-link to="/technologie2">
                            <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img style="margin-left: 15px;" src="../assets/img/right_row.svg"> </button>
                        </router-link>
                        </div>
                    </div>
                    <div class="col-md-6" style="text-align: left; margin-left: -15px;">
                        <img src="../assets/img/technologie2.jpg">
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 30px; padding-top: 0; padding-bottom: 0;">
            <div class="container-fluid">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6" style="text-align: right; margin-right: -15px;">
                        <img src="../assets/img/technologie3.jpg">
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
                        <div style="text-align: left; padding: 30px;">
                            <p style="font-size: xx-large; color: black; font-weight: bold;">{{translate('technologie3_title') }}</p>
                            <br>
                            <br>
                            <router-link to="/technologie3">
                            <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img style="margin-left: 15px;" src="../assets/img/right_row.svg"> </button>
                        </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 50px;">
            <div class="container" style="margin-top: 0px;">
                <div class="row text-center justify-content-center">
                    <div class="col-12 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; padding: 30px; background-color: #FCEEF0;">
                            <div style="text-align: left; flex-grow: 1; font-size: large; color: #CF0020;">
                                <span>{{ translate('technologie_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>





<script>
import MainNav from '@/components/MainNav2.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundImage1 from '../assets/img/technologie_header.jpg';
import BackgroundImage2 from '../assets/img/iso_background.jpg';
import BackgroundImage3 from '../assets/img/iso_mobile_back.jpg';





export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            backgroundImage3: BackgroundImage3,
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'CITExpress | Technologies';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
    }
}
</script>

<style>
.service-box {
    background-color: rgba(207, 0, 32, 0.5);
    color: white;
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
    height: 100%;
    cursor: pointer;
}

.service-box:hover {
    background-color: rgba(82, 79, 80, 0.5);
    border: 2px solid #efefef;
}

.service-box-mobile {
    border: 1px solid #CF0020;
    margin-top: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 250px;
    border-radius: 20px;
}

.laptop-header header .navbar {
    top: 0;
    left: 0;
}

.custom-btn {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn:hover {
    background-color: #CF0020;
    color: white;
}

.solution-box {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    border: 1px solid white;
    text-align: center;
    color: black;
    padding: 25px;
    height: 100%;
}

.custom-btn2 {
    background-color: rgba(247, 243, 243, 0.1);
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn2:hover {
    background-color: #CF0020;
    color: white;
}

.custom-btn3 {
    background-color: #CF0020;
    border: 1px solid white;
    color: white;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    width: 150px;
}

.custom-btn3:hover {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
}
.review-box {
    background-color: white;
    border-radius: 20px;
    border: 1px solid white;
    color: black;
    padding: 25px;
    margin-left: 15px;
}

.laptop-section {
    display: block;
}

.mobile-section {
    display: none;
}

.ipad-section {
    display: none;
}

@media (max-width: 767px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: block;
    }

    .ipad-section {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: none;
    }

    .ipad-section {
        display: block;
    }
}

.btn-contact {
    background-color: #CF0020;
    border: none;
    margin-right: 20px;
}

.btn-contact:hover {
    background-color: #940B20;
}

.btn-contact-mobile {
    background-color: #CF0020;
    border: none;
}

.btn-contact-mobile:hover {
    background-color: #940B20;
}
</style>
