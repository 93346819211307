export default {
    data() {
        return {
            fr: {
                header_text1: "Au-delà du volant : ",
                header_text2: "Nos chauffeurs, les ambassadeurs de votre marque.",
                header_text3: "Ils incarnent vos valeurs sur chaque route, assurant une arrivée à bon port Avec un service irréprochable garantissant ainsi satisfaction et confiance à chaque trajet.",
                header_text4: "Fiabilité Pharma :",
                header_text5: "Excellence certifiée, ISO 9001 pour vos livraisons précises.",
                header_text6: "Notre maîtrise des réseaux de santé belges garantit une distribution impeccable, du secteur pharma à l'hôpital, élevant la norme de confiance.",
                header_text7: "Main à Main :",
                header_text8: "Garantie absolue de ponctualité et d'intégrité.",
                header_text9: "Vos envois, précieux et fragiles, sont traités avec le plus grand soin, assurant une arrivée en parfait état, à chaque fois.",
                action1_title: "EFFECTUER UN ENVOI",
                action2_title: "DEMANDEZ UN DEVIS",
                action3_title: "DEVENIR UN PARTENAIRE",
                why_question1: "Pourquoi",
                why_question2: "choisissent d'utiliser CIT Express?",
                section1_text1: "CIT Express transforme vos attentes en réalité, adaptant ses solutions à vos exigences",
                solution1_title: "Les Secteurs de Livraison",
                solution1_contenu: "Explorez nos services de livraison adaptés à 14 secteurs, couvrant un large éventail allant du domaine médical à l'industrie aérospatiale",
                solution2_title: "Notre Flotte",
                solution2_contenu: "Découvrez notre flotte de véhicules variée, garantissant un service de livraison fiable et rapide pour répondre à tous les besoins.",
                solution3_title: "Technologie",
                solution3_contenu: "Nous combinons l'excellence opérationnelle avec des technologies de pointe pour offrir un service de livraison excédant attentes nos clients",
                solution4_title: "ISO 9001-2018",
                solution4_contenu: "Chez CIT Express, notre adhésion aux normes ISO garantit une qualité et une efficacité inégalées, assurant la satisfaction totale de nos clients",
                see_more: "Voir plus",
                citexpress_name: "CIT EXPRESS",
                citexpress_name_description: "leader en Belgique depuis 1995",
                citexpress_presentation: "CIT Express, leader en Belgique depuis 1995, offre des solutions logistiques sur mesure pour répondre à vos besoins d'expédition, que vous soyez particulier, e-commerçant, petite entreprise ou multinationale...",
                satisfied_clients: "Des milliers de clients satisfaits",
                satisfied_clients_description: "Plusieurs d'entre eux, nous leur avons demandé à quel point ils étaient satisfaits de nos services. Voici leurs déclarations.",
                give_review: "DONNER UN AVIS",
                user_name1: "Marc B. B2B",
                user_review1: "Auparavant, le suivi était compliqué avec un seul transporteur. Aujourd'hui, grâce à CIT Express qui gère l'ensemble de nos transporteurs, tout est pris en charge de manière fluide et efficace.",
                user_name2: "Amina R. B2B",
                user_review2: "Chercher un  transporteur à la hauteur de nos exigences c'est quasi impossible, CIT Express a reussi le par.",
                user_name3: "Alan D. C2C",
                user_review3: "Dans une situation délicate, CIT Express a assuré une livraison rapide et sécurisée de documents importants, me sauvant ainsi. Un service professionnel que je recommande.",
                user_name4: "Jean-Michel L. B2B",
                user_review4: "Collaborant avec plusieurs transporteurs, cela implique de nombreux contacts et diverses factures. Avec CIT Express, nous économisons un temps précieux en consolidant ces processus.",

                //Navbar
                home: "Accueil",
                services: "Services",
                track_shipment: "Suivi de colis",
                contact_us: "Contactez-nous",
                my_cit: "My CIT",

                login_text1: "Prêt à envoyer?",
                login_text2: "Connectez-vous ou inscrivez-vous maintenant",
                form_header: "Connectez-vous:",
                email_placeholder: "Adresse e-mail",
                password_placeholder: "Mot de passe",
                login: "SE CONNECTER",
                not_yet_subscribed: "Pas encore inscrit ?",
                subscribe: "INSCRIVEZ-VOUS",

                //Notre Flotte
                notre_flotte_header: "Notre Flotte : Diversifiée, Moderne et Écologique",
                notre_flotte_desc: "Chez CIT Express, notre flotte de véhicules est le cœur de notre capacité à fournir un service de livraison rapide et fiable. Nous comprenons que chaque livraison est unique, c'est pourquoi notre flotte est composée d'une variété de véhicules adaptés à tous les besoins.",

                notre_flotte_title1: "Véhicules Électriques",
                notre_flotte_title1_soustitle1: "Scooters Électriques",
                notre_flotte_title1_soustitle1_desc: "Parfaits pour la navigation rapide en milieu urbain, nos scooters électriques sont à la fois écologiques et efficaces.",
                notre_flotte_title1_soustitle2: "Vélos Électriques",
                notre_flotte_title1_soustitle2_desc: "Idéaux pour les livraisons de proximité, ils combinent la maniabilité des vélos traditionnels avec la puissance assistée, permettant des livraisons rapides sans émissions.",
                notre_flotte_title2: "Véhicules à Moteur",
                notre_flotte_title2_soustitle1: "Fourgonnettes",
                notre_flotte_title2_soustitle1_desc: "Nos fourgonnettes sont idéales pour des livraisons plus volumineuses ou multiples, offrant suffisamment d'espace tout en étant agiles en ville.",
                notre_flotte_title2_soustitle2: "Camions de Livraison",
                notre_flotte_title2_soustitle2_desc: "Pour les cargaisons plus importantes ou les livraisons spécialisées, nos camions sont équipés pour transporter en toute sécurité une grande variété de marchandises.",

                notre_flotte_title3: "Véhicules Spécialisés",
                notre_flotte_title3_soustitle1: "Véhicules Réfrigérés",
                notre_flotte_title3_soustitle1_desc: "Ces véhicules sont essentiels pour le transport de produits nécessitant un contrôle de température, comme certains médicaments ou équipements technologiques sensibles.",
                notre_flotte_title3_soustitle2: "Véhicules de Livraison de Haute Sécurité",
                notre_flotte_title3_soustitle2_desc: "Utilisés pour le transport de documents ou d'objets de valeur nécessitant une sécurité accrue, ces véhicules sont équipés de systèmes de sécurité avancés.",

                notre_flotte_title4: "Engagement envers la Durabilité : Transition vers l'Électrique",
                notre_flotte_title4_desc1: "Nous sommes engagés dans une transition progressive vers une flotte entièrement électrique, réduisant ainsi notre empreinte carbone et contribuant à un avenir plus durable.",
                notre_flotte_title4_desc2: "Notre flotte est régulièrement entretenue et mise à jour pour garantir la meilleure performance et fiabilité. Chez CIT Express, nous sommes fiers de notre capacité à répondre à tous les besoins de livraison, tout en restant engagés envers la responsabilité environnementale et l'innovation.",

                about_us_header: "A PROPOS DE NOUS",
                about_us_sheader: "Pionniers de la Livraison Urbaine Innovante",
                about_us_section1_title: "Les Origines à Bruxelles, 1996",
                about_us_section1_contenu: "L'aventure de CIT Express a commencé en 1996 à Bruxelles, une ville où l'innovation se mêle à un riche héritage culturel. Face aux défis logistiques urbains, comme les embouteillages et les manifestations, nous avons eu une vision révolutionnaire : transformer la livraison en milieu urbain",
                inovation: "L'Innovation :",
                inovation_desc: "Au-delà de la Livraison en Scooter et Vélo",
                inovation_text: "Notre concept initial s'est rapidement distingué par l'utilisation de scooters et de vélos pour des livraisons rapides et efficaces. Mais notre véritable avancée a été notre précurseur dans l'adoption de technologies novatrices.",
                about_us_services_title: "Précurseurs en Technologie de Communication",
                about_us_service1_title: "Communication Radio",
                about_us_service1_contenu: "Nous avons été parmi les premiers à utiliser la communication radio pour coordonner nos livraisons, augmentant ainsi notre efficacité opérationnelle.",
                about_us_service2_title: "Introduction des SMS",
                about_us_service2_contenu: "L'adoption précoce des SMS a permis une communication plus rapide et plus directe avec nos équipes sur le terrain.",
                about_us_service3_title: "Pionniers du GPS",
                about_us_service3_contenu: "L'intégration du GPS a révolutionné notre capacité à suivre les livraisons en temps réel, offrant une transparence et une fiabilité sans précédent.",
                inovation_numerique: "Innovation Numérique",
                inovation_numerique1_title: "1er Site de Prise de Commande",
                inovation_numerique1_contenu: "Nous avons lancé l'un des premiers sites de commande de livraison en ligne, offrant à nos clients une facilité d'utilisation et une efficacité accrue.",
                inovation_numerique2_title: "Suivi et Facturation Électronique",
                inovation_numerique2_contenu: "En introduisant le suivi de commande en ligne et la facturation électronique, nous avons non seulement simplifié le processus pour nos clients, mais également réduit notre empreinte écologique.",
                leadership_title: "Un Héritage de Leadership et d'Innovation",
                leadership_text1: "Ces innovations ont non seulement établi CIT Express comme un leader dans le domaine de la livraison urbaine, mais elles ont également pavé la voie pour les technologies que de nombreux acteurs majeurs du secteur utilisent aujourd'hui. Nous sommes fiers d'avoir été des précurseurs, constamment à la recherche de nouvelles façons d'améliorer nos services et d'anticiper les besoins de nos clients.",
                leadership_text2: "Chez CIT Express, notre histoire est celle d'une innovation constante et d'un engagement envers l'excellence. Nous continuons à évoluer, toujours en quête de nouvelles façons de servir nos clients avec efficacité et fiabilité.",
                contactus_paragraphe1: "Laissez-nous vous montrer comment CIT Express peut répondre à vos exigences de livraison urbaine de manière efficace et fiable.",
                contactus_paragraphe2: "Contactez-nous aujourd'hui pour une collaboration exceptionnelle.",
                contacus_button: "Contactez-nous",

                iso_title: "ISO 9001-2018",
                iso_title_s1: "Qualité",
                iso_title_s2: "Gestion",
                iso_title_s3: "Systèmes",
                norme_iso: "Normes ISO:",
                norme_iso_desc: "Le Fondement de Notre Excellence dans la Livraison Spécialisée",
                iso_icon_title: "Engagement envers les Standards de Qualité ISO",
                iso_icon_desc: "Chez CIT Express, l'adhésion aux normes ISO est au cœur de notre engagement envers une qualité et une efficacité inégalées. Nos processus sont rigoureusement conçus pour respecter et surpasser ces standards internationaux, garantissant ainsi une satisfaction totale de nos clients.",
                iso_app_title: "Application des Normes ISO dans Nos Services Spécialisés",
                iso_app1_title: "ISO 9001 pour la Gestion de la Qualité",
                iso_app1_contenu: "Tous nos processus, de la gestion des opérations à la prestation des services de livraison, sont conformes à l'ISO 9001, assurant une qualité constante et fiable.",
                iso_app2_title: "ISO 14001 pour l'Écologie",
                iso_app2_contenu: "Nous nous engageons à minimiser notre impact environnemental à travers des pratiques conformes à l'ISO 14001, soulignant notre responsabilité écologique.",
                iso_app3_title: "ISO 45001 la Santé et la Sécurité au Travail",
                iso_app3_contenu: "La sécurité et le bien-être de notre équipe et de nos clients demeurent une priorité absolue, constamment guidée par les normes de l'ISO 45001 et l'engagement de tous.",
                iso_norme_title: "Spécialisation Sectorielle et Normes Associées",
                iso_norme1_title: "Secteur Pharmaceutique",
                iso_norme1_contenu: "Nous suivons les normes strictes relatives à la manutention et au transport des produits pharmaceutiques, incluant les Bonnes Pratiques de Distribution (BPD) et les directives spécifiques à la chaîne du froid.",
                iso_norme2_title: "Secteur Électronique et Technologique",
                iso_norme2_contenu: "Pour le transport de produits électroniques sensibles, nous appliquons des normes spécifiques qui assurent la protection contre les décharges électrostatiques et les dommages mécaniques.",
                iso_norme3_title: "Secteur Juridique et Financier",
                iso_norme3_contenu: "Nous respectons des protocoles de confidentialité et de sécurité rigoureux pour garantir la sécurité et la confidentialité des documents sensibles.",
                iso_norme4_title: "Secteur Aérospatial",
                iso_norme4_contenu: "Les exigences spécifiques de ce secteur sont respectées, y compris le transport de matériaux et composants critiques, en conformité avec les standards de qualité et de sécurité aérospatiaux.",
                formation_continue_title: "Formation et Sensibilisation Continues",
                formation_continue_desc: "Notre personnel reçoit une formation continue sur les normes ISO et les exigences spécifiques à chaque secteur. Cette approche assure une expertise et une conformité sans faille dans toutes nos opérations.",
                audit_amelioration_title: "Audit et Amélioration Continue",
                audit_amelioration_desc: "Des audits réguliers sont menés pour vérifier la conformité avec les normes ISO et identifier les opportunités d'amélioration. Cette démarche d'amélioration continue renforce notre engagement envers l'excellence.",
                iso_certif_2018: "En intégrant ces normes dans notre modèle opérationnel, CIT Express se distingue comme un prestataire de services de livraison spécialisé, fiable et respectueux des normes les plus élevées pour chacun des secteurs que nous desservons.",

                secteurs_header: "Les Secteurs de Livraison",
                secteurs_header_desc1: "Explorez nos services de livraison adaptés à 14 secteurs, du médical à l'aérospatiale.",
                secteurs_header_desc2: "Choisissez votre domaine dans le menu déroulant et découvrez comment notre expertise assure sécurité et rapidité pour vos expéditions.",
                secteurs_sheader: "CIT Express, 30 ans d'expertise, votre partenaire de confiance pour des livraisons sur mesure!",
                secteur1_title: "Secteur Médical et Pharmaceutique",
                secteur1_contenu: "Livraisons urgentes de médicaments, vaccins, échantillons biologiques, équipements médicaux. Transport à température contrôlée pour les articles sensibles.",
                secteur2_title: "Technologie et Électronique",
                secteur2_contenu: "Livraison de composants électroniques sensibles, équipements informatiques. Besoin de manutention soignée et protection contre les chocs.",
                secteur3_title: "Commerce de Détail et E-commerce",
                secteur3_contenu: "Optimisez votre commerce en ligne avec une livraison rapide, une gestion de retours efficace, et une synchronisation des stocks via des API. Assurez un service client réactif.",
                secteur4_title: "Industrie Automotrice",
                secteur4_contenu: "Transport urgent de pièces détachées, composants pour la production. Livraisons just-in-time pour maintenir la continuité de la chaîne de production.",
                secteur5_title: "Recherche et Développement",
                secteur5_contenu: "Service garantit un transport sécurisé pour votre matériel de recherche, échantillons scientifiques et prototypes, avec une attention particulière portée au contrôle minutieux de la température.",
                secteur6_title: "Consultance et Formation",
                secteur6_contenu: "Livraison rapide de documents importants, matériel de présentation et de formation, équipements pour audits ou évaluations. Importance de la rapidité et de la fiabilité.",

                sous_secteur1_title: "Industrie Alimentaire",
                sous_secteur1_contenu: "Transport de denrées périssables, fournitures de restauration, livraisons urgentes pour les supermarchés. Exigences en matière de température et de fraîcheur.",
                sous_secteur2_title: "Construction et Ingénierie",
                sous_secteur2_contenu: "Pour le transport de produits électroniques sensibles, nous appliquons des normes spécifiques qui assurent la protection contre les décharges électrostatiques et les dommages mécaniques.",
                sous_secteur3_title: "Juridique et Financier",
                sous_secteur3_contenu: "Nous respectons des protocoles de confidentialité et de sécurité rigoureux pour garantir la sécurité et la confidentialité des documents sensibles.",
                sous_secteur4_title: "Secteur Aérospatial",
                sous_secteur4_contenu: "Les exigences spécifiques de ce secteur sont respectées, y compris le transport de matériaux et composants critiques, en conformité avec les standards de qualité et de sécurité aérospatiaux.",
                sous_secteur5_title: "Secteur Public et Gouvernemental",
                sous_secteur5_contenu: "Nous suivons les normes strictes relatives à la manutention et au transport des produits pharmaceutiques, incluant les Bonnes Pratiques de Distribution (BPD) et les directives spécifiques à la chaîne du froid.",
                sous_secteur6_title: "Mode et Textile",
                sous_secteur6_contenu: "Pour le transport de produits électroniques sensibles, nous appliquons des normes spécifiques qui assurent la protection contre les décharges électrostatiques et les dommages mécaniques.",
                sous_secteur7_title: "Événementiel et Spectacle",
                sous_secteur7_contenu: "Nous respectons des protocoles de confidentialité et de sécurité rigoureux pour garantir la sécurité et la confidentialité des documents sensibles.",
                sous_secteur8_title: "Arts et Antiquités",
                sous_secteur8_contenu: "Les exigences spécifiques de ce secteur sont respectées, y compris le transport de matériaux et composants critiques, en conformité avec les standards de qualité et de sécurité aérospatiaux.",

                technologie_header: "Technologie à Votre Service pour une Expérience Transparente, Facile et Intuitive",
                technologie_sheader: "Chez CIT Express, nous combinons l'excellence opérationnelle avec des technologies de pointe pour offrir un service de livraison qui non seulement répond, mais excède les attentes de nos clients.",
                technologie1_title: "Fonctionnalités Technologiques Additionnelles",
                technologie2_title: "Suivi de Livraison Accessible et Transparent",
                technologie3_title: "Comptabilité Intuitive et Efficace",
                technologie_desc: "L'innovation technologique chez CIT Express est dédiée à améliorer continuellement l'expérience de nos clients, en offrant des solutions logistiques qui sont non seulement avancées, mais aussi parfaitement alignées avec leurs besoins opérationnels et financiers.",

                technologie1_tech1_title: "Interface de Commande Simplifiée",
                technologie1_tech1_desc: "Notre plateforme en ligne est conçue pour une expérience utilisateur intuitive, permettant une prise de commande rapide et sans effort.",
                technologie1_tech2_title: "Notifications et Alertes Automatiques",
                technologie1_tech2_desc: "Restez informé à chaque étape de votre livraison grâce à des notifications et mises à jour automatiques.",
                technologie1_tech3_title: "Intégration avec les Systèmes d'Entreprise",
                technologie1_tech3_desc: "Notre plateforme s'intègre sans heurt aux systèmes ERP et CRM, pour une gestion centralisée et efficace des commandes de livraison.",
                technologie1_tech4_title: "Options de Paiement Sécurisées",
                technologie1_tech4_desc: "Nous proposons diverses options de paiement, toutes sécurisées, pour répondre aux besoins de nos clients.",
                technologie1_tech5_title: "Interface de Commande Simplifiée",
                technologie1_tech5_desc: "Notre équipe d'assistance clientèle est disponible 24/7 pour répondre à toutes vos questions et résoudre rapidement tout problème.",
                technologie2_tech1_title: "Géolocalisation en Temps Réel",
                technologie2_tech1_desc: "Nos clients et les destinataires des colis peuvent suivre l'acheminement de leurs envois en temps réel grâce à notre système de géopositionnement avancé.",
                technologie2_tech2_title: "Programme de Suivi Interactif",
                technologie2_tech2_desc: "Les destinataires sont intégrés dans notre programme de suivi, leur permettant de consulter facilement l'état et l'emplacement de leur envoi, pour une transparence et une tranquillité d'esprit totales.",
                technologie3_tech1_title: "Exigence du Numéro de Job ou de PO",
                technologie3_tech1_desc: "Pour faciliter le travail de votre département comptable, notre système exige la saisie d'un numéro de job ou de bon de commande (PO) avant toute prise de commande. Cette pratique assure une allocation précise des coûts et une gestion comptable simplifiée.",
                technologie3_tech2_title: "Rapports Comptables Personnalisés et Exportables",
                technologie3_tech2_desc: "Nos clients peuvent générer et exporter des rapports comptables personnalisés, offrant une intégration fluide avec leurs propres systèmes financiers.",

                about_us: "À propos de nous",
                terms_of_use: "Conditions d'utilisation",
                privacy_policy: "Politique de confidentialité",
                accessibility_statement: "Déclaration d'accessibilité",
                all_rights_resereved: "Tous droits réservés",

                username: "Nom d'utilisateur",

                suivant: "Suivant",
                nom_entreprise: "Nom de l'entreprise?",
                secteur_activite: "Secteur d'activité?",
                contact_principale: "Contact principal (Nom et poste)?",
                address_email: "Adresse e-mail?",
                phone_number: "Numéro de téléphone",
                repondez_ici: "Répondez ici...",
                question1: "Quelle est la fréquence de vos besoins en livraison?",
                question1_choice1: "Quotidienne",
                question1_choice2: "Hebdomadaire",
                question1_choice3: "Mensuelle",
                question1_choice4: "Autre",
                question2: "Quel est le volume moyen de vos envois? (Nombre de colis ou poids approximatif par envoi)",
                question3: "Quelles sont les destinations principales de vos envois?",
                question3_choice1: "National",
                question3_choice2: "International",
                question3_choice3: "Les deux",
                question4: "Quel type de marchandises expédiez-vous?",
                question4_choice1: "Documents",
                question4_choice2: "Colis",
                question4_choice3: "Palettes",
                question4_choice4: "Marchandises spéciales",
                question5: "Y a-t-il des exigences particulières concernant vos marchandises? (Fragilité, température contrôlée, dangereuses, etc.)",
                question5_choice1: "Oui",
                question5_choice2: "Non",
                question6: "Quels services de livraison recherchez-vous?",
                question6_choice1: "Livraison standard",
                question6_choice2: "Express",
                question6_choice3: "Jour même",
                question7: "Avez-vous besoin de services supplémentaires? (Assurance, suivi en temps réel, livraison contre signature, etc.)",
                question7_choice1: "Oui",
                question7_choice2: "Non",
                question8: "Avez-vous déjà utilisé des services de livraison express?",
                question8_choice1: "Oui",
                question8_choice2: "Non",
                question9: "Quels sont vos critères principaux pour choisir un service de livraison? (Coût, fiabilité, vitesse, service client, etc.)",
                question10: "Avez-vous des attentes ou des préoccupations spécifiques concernant le service de livraison?",
                question11: "Quel est votre budget approximatif pour les services de livraison?",
                question12: "Quelles sont vos préférences en matière de facturation et de paiement?",
                thanks_message1: "Merci d'avoir demandé un devis.",
                thanks_message2: "Un de nos experts métiers traite votre demande. Vous serez contacté dans les plus brefs délais pour discuter de vos besoins de livraison et de nos solutions.",

                contactus_header: "Contactez-nous",
                contactus_sheader1: "Bienvenue chez CIT Express,experts de la livraison express",
                contactus_sheader2: "Besoin d'aide ? Contactez-nous !",
                contactus_form_input1: "Nom",
                contactus_form_input2: "Société",
                contactus_form_input3: "Téléphone",
                contactus_form_input4: "Email",
                contactus_form_input5: "Sujet",
                contactus_form_input6: "Message",
                contactus_form_button: "Envoyer",
                contactus_text1: "Numéro de téléphone dédié, pour une assistance immédiate :",
                contactus_text2: "Notre équipe est disponible",
                contactus_text3: "Pour répondre rapidement à vos préoccupations urgentes.",

                construction_text1: "Page en cours de construction",
                construction_text2: "Nous sommes désolés, mais cette page est actuellement en cours de construction.",
                construction_text3: "Revenez bientôt pour voir les mises à jour.",
                nos_service : "Nos services",
                contactus_message : "Merci, nous vous contacterons bientôt"




            }
        }
    }
}
