<template>
    <div ref="scrollTarget"></div>
    <MainNav />

    <section class="laptop-section">
        <section class="background-container" style="margin-top: 50px; padding-bottom: 50px; height: 600px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">


            <div class="container-fluid"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%;">
                <div class="row gx-5" style="margin-left: 120px; margin-top: 150px;">
                    <div class="col-md-12">
                        <span style="color: #CF0020; font-size: 2.2em; font-weight: 600;">
                            {{ translate('iso_title') }}
                        </span>
                        <br>
                        <span style="color: black; font-size: 1.3em; font-weight: 400;">
                            {{ translate('iso_title_s1') }}
                        </span>
                        <br>
                        <span style="color: black; font-size: 1.3em; font-weight: 400;">
                            {{ translate('iso_title_s2') }}
                        </span>
                        <br>
                        <span style="color: black; font-size: 1.3em; font-weight: 400;">
                            {{ translate('iso_title_s3') }}
                        </span>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <p style="color: #CF0020; font-weight: bold; font-size: 1.7em;">{{ translate('norme_iso') }}</p>
                    <p style="color: #000; font-weight: 400; font-size: 1.2em;">{{ translate('norme_iso_desc') }}</p>
                </div>
            </div>
        </section>

        <section style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="d-flex justify-content-end align-items-center border"
                        style="width: 850px; background-color: #F7F7F7; padding: 20px; padding-left: 50px; padding-right: 50px; border-radius: 10px;">
                        <img src="../assets/img/iso_icon.svg" style="max-width: 200px;">
                        <div style="margin-left: 20px;">
                            <p style="color: #CF0020; font-weight: bold; font-size: 1.8em; text-align: left;">{{
                translate('iso_icon_title') }}</p>
                            <p style="color: #000; font-weight: 400; font-size: 0.9em; text-align: left;">{{
                translate('iso_icon_desc') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 70px; padding-top: 20px; padding-bottom: 50px;"
            :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-position': 'center center', 'background-attachment': 'fixed', 'background-size': 'cover' }">
            <div class="container"
                style="color: black; text-align: center; font-size: 1.5em; font-weight: 400; width: 40%; margin-top: 50px;">
                {{ translate('iso_app_title') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: white; padding-top: 20px; width: 90%; text-align: left; padding-left: 40px; padding-right: 40px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/iso_app1.svg" style="max-width: 100px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: medium; margin-left: 10px;">{{
                translate('iso_app1_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('iso_app1_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: white; padding-top: 20px; width: 90%; text-align: left; padding-left: 40px; padding-right: 40px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/iso_app2.svg" style="max-width: 100px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: medium; margin-left: 10px;">{{
                translate('iso_app2_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('iso_app2_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: white; padding-top: 20px; width: 90%; text-align: left; padding-left: 40px; padding-right: 40px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/iso_app3.svg" style="max-width: 100px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: medium; margin-left: 10px;">{{
                translate('iso_app3_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('iso_app3_contenu') }}</span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container"
                style="color: black; text-align: center; font-size: 1.5em; font-weight: 400; width: 40%; margin-top: 80px;">
                {{ translate('iso_norme_title') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: white; padding-top: 20px; width: 90%; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/iso_norme1.svg" style="max-width: 100px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: medium; margin-left: 10px;">{{
                translate('iso_norme1_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 30px; padding-bottom: 20px;">
                                <span>{{ translate('iso_norme1_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: white; padding-top: 20px; width: 90%; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/iso_norme2.svg" style="max-width: 100px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: medium; margin-left: 10px;">{{
                translate('iso_norme2_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 30px; padding-bottom: 20px;">
                                <span>{{ translate('iso_norme2_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: white; padding-top: 20px; width: 90%; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/iso_norme3.svg" style="max-width: 100px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: medium; margin-left: 10px;">{{
                translate('iso_norme3_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 30px; padding-bottom: 20px;">
                                <span>{{ translate('iso_norme3_contenu') }}</span>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: white; padding-top: 20px; width: 90%; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/iso_norme4.svg" style="max-width: 100px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: medium; margin-left: 10px;">{{
                translate('iso_norme4_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 30px; padding-bottom: 20px;">
                                <span>{{ translate('iso_norme4_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 70px; padding-top: 0; padding-bottom: 0;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6" style="text-align: right; margin-right: -15px;">
                        <img src="../assets/img/formation_continue.jpg">
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7;">
                        <div style="text-align: left; padding-left: 30px;">
                            <p style="font-size: 2.5em; color: #CF0020; font-weight: bold;">{{
                translate('formation_continue_title') }}
                            </p>
                            <br>
                            <p>{{ translate('formation_continue_desc') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 70px; padding-top: 0; padding-bottom: 0;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7;">
                        <div style="text-align: left; padding-left: 30px;">
                            <p style="font-size: 2.5em; color: #CF0020; font-weight: bold;">{{
                translate('audit_amelioration_title') }}
                            </p>
                            <br>
                            <p>{{ translate('audit_amelioration_desc') }}</p>
                        </div>
                    </div>
                    <div class="col-md-6" style="text-align: right; margin-left: -15px;">
                        <img src="../assets/img/audit_amelioration.jpg">
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 120px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-7 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; background-color: #F7F7F7; padding: 40px; ">
                            <div style="text-align: left; flex-grow: 1; font-size: large; color: black;">
                                <span>{{ translate('iso_certif_2018') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img style="max-width: 140px; margin-left: 980px; margin-top: -65px;" src="../assets/img/iso_2018.svg">
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <div class="laptop-header" style="margin-top: 50px;">
            <header class="position-relative">
                <!-- Image -->
                <transition name="image-fade" mode="out-in">
                    <img src="../assets/img/iso_header_mobile.jpg" class="d-block w-100" alt="Background Image">
                </transition>

                <!-- Navbar -->
                <MainNav />

            </header>
        </div>

        <section style="padding-top: 20px; padding-bottom: 80px;"
            :style="{ backgroundImage: 'url(' + backgroundImage3 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12">
                        <p style="color: #CF0020; font-size: x-large; font-weight: bold;">{{ translate('iso_title') }}
                        </p>
                        <p style="color: black; font-size: large;">{{ translate('iso_title_s1') }} - {{
                translate('iso_title_s2') }} - {{ translate('iso_title_s3') }}</p>
                    </div>
                    <div class="col-12" style="margin-top: 40px;">
                        <p style="color: #CF0020; font-size: x-large; font-weight: bold;">{{ translate('norme_iso') }}
                        </p>
                        <p style="color: black; font-size: medium;">{{ translate('norme_iso_desc') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 10px;">
            <div class="container" style="margin-top: 10px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-11 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 10px; background-color: #F7F7F7; padding-top: 30px;">
                                        <div>
                                            <img style="max-width: 200px;" src="../assets/img/iso_icon.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.3em;">
                                                {{ translate('iso_icon_title') }} </p>
                                            <p style="margin-top: 15px">{{ translate('iso_icon_desc') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 40px; padding-bottom: 20px; margin-top: 30px;;"
            :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
            <div class="container"
                style="color: black; text-align: center; font-size: large; font-weight: 400; color: black;">
                {{ translate('iso_app_title') }}
            </div>

            <div class="container" style="margin-top: 30px; height: 400px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel1" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_app1.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                translate('iso_app1_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                translate('iso_app1_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_app2.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                translate('iso_app2_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                translate('iso_app2_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_app3.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                translate('iso_app3_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                translate('iso_app3_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container"
                style="color: black; text-align: center; font-size: large; font-weight: 400; color: black; margin-top: 20px;">
                {{ translate('iso_norme_title') }}
            </div>

            <div class="container" style="margin-top: 30px; height: 400px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel2" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_norme1.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                translate('iso_norme1_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                translate('iso_norme1_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_norme2.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                translate('iso_norme2_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                translate('iso_norme2_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_norme3.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                translate('iso_norme3_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                translate('iso_norme3_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_norme4.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                translate('iso_norme4_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                translate('iso_norme4_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 0px; padding-bottom: 10px; margin-top: 20px;">
            <div class="container">
                <div class="row justify-content-center" style="padding-left: 15px; padding-right: 15px;">
                    <div class="col-11" style="padding: 0;">
                        <img src="../assets/img/formation_continue-mobile.jpg">
                    </div>
                    <div class="col-11 border"
                        style="background-color: #F7F7F7; padding: 20px; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; margin-top: -5px;">
                        <p style="font-size: larger; color: #CF0020; font-weight: bold; text-align: center;">{{
                translate('formation_continue_title') }}</p>
                        <br>
                        <p style="font-size: medium; color: black; font-weight: 400; text-align: center;">{{
                translate('formation_continue_desc') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 0px; padding-bottom: 10px; margin-top: 20px;">
            <div class="container">
                <div class="row justify-content-center" style="padding-left: 15px; padding-right: 15px;">
                    <div class="col-11" style="padding: 0;">
                        <img src="../assets/img/audit_amelioration-mobile.jpg">
                    </div>
                    <div class="col-11 border"
                        style="background-color: #F7F7F7; padding: 20px; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; margin-top: -5px;">
                        <p style="font-size: larger; color: #CF0020; font-weight: bold; text-align: center;">{{
                translate('audit_amelioration_title') }}</p>
                        <br>
                        <p style="font-size: medium; color: black; font-weight: 400; text-align: center;">{{
                translate('audit_amelioration_desc') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 10px; padding-top: 10px; padding-bottom: 80px;">
            <div class="container" style="margin-top: 20px;">
                <div class="row text-center justify-content-center">
                    <div class="col-11 d-flex">
                        <div class="d-flex flex-column border"
                            style="border-radius: 10px; background-color: #F7F7F7; padding: 30px; ">
                            <div style="text-align: center; flex-grow: 1; font-size: large; color: black;">
                                <span>{{ translate('iso_certif_2018') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="text-align: center; margin-top: -50px;">
                <img style="max-width: 130px;" src="../assets/img/iso_2018.svg">
            </div>
            </div>
        </section>

    </section>

    <section class="ipad-section">
        <div class="laptop-header" style="margin-top: 50px;">
            <header class="position-relative">
                <!-- Image -->
                <transition name="image-fade" mode="out-in">
                    <img src="../assets/img/iso_header.jpg" class="d-block w-100" alt="Background Image">
                </transition>

                <!-- Navbar -->
                <MainNav />

                <div class="position-absolute bottom-0 text-white mobile-prices"
                    style="width: 100%; margin-bottom: 15px; padding-left: 20px; padding-right: 20px;">
                    <p style="font-weight: bold; font-size: x-large; color: #CF0020;">{{ translate('iso_title') }}</p>
                    <p style="font-weight: 400; font-size: medium; color: black;">{{ translate('iso_title_s1') }} <br>
                        {{ translate('iso_title_s2') }} <br> {{ translate('iso_title_s3') }}</p>
                </div>
            </header>
        </div>

        <section style="padding-top: 20px; padding-bottom: 20px;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12" style="margin-top: 0px;">
                        <p style="color: #CF0020; font-size: x-large; font-weight: bold;">{{ translate('norme_iso') }}
                        </p>
                        <p style="color: black; font-size: medium;">{{ translate('norme_iso_desc') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 0; padding-bottom: 0;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="d-flex justify-content-end align-items-center border"
                        style="width: 850px; background-color: #F7F7F7; padding: 25px; padding-left: 25px; padding-right: 25px; border-radius: 10px;">
                        <img src="../assets/img/iso_icon.svg" style="max-width: 200px;">
                        <div style="margin-left: 20px;">
                            <p style="color: #CF0020; font-weight: bold; font-size: x-large; text-align: left;">{{
                translate('iso_icon_title') }}</p>
                            <br>
                            <p style="color: #000; font-weight: 400; font-size: medium; text-align: left;">{{
                translate('iso_icon_desc') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 40px; padding-bottom: 20px; margin-top: 30px;;"
            :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
            <div class="container"
                style="color: black; text-align: center; font-size: large; font-weight: 400; color: black;">
                {{ translate('iso_app_title') }}
            </div>

            <div class="container" style="margin-top: 30px; height: 350px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel3" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_app1.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                                                translate('iso_app1_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                                                translate('iso_app1_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_app2.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                                                translate('iso_app2_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                                                translate('iso_app2_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_app3.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                                                translate('iso_app3_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                                                translate('iso_app3_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container"
                style="color: black; text-align: center; font-size: large; font-weight: 400; color: black; margin-top: 10px;">
                {{ translate('iso_norme_title') }}
            </div>

            <div class="container" style="margin-top: 30px; height: 350px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel4" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_norme1.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                                                translate('iso_norme1_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                                                translate('iso_norme1_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_norme2.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                                                translate('iso_norme2_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                                                translate('iso_norme2_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_norme3.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                                                translate('iso_norme3_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                                                translate('iso_norme3_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: white; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/iso_norme4.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 400; font-size: medium;">{{
                                                translate('iso_norme4_title') }}</p>
                                            <p style="margin-top: 10px; color: black; font-size: medium;">{{
                                                translate('iso_norme4_contenu') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 40px; padding-top: 0; padding-bottom: 0;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6" style="text-align: right; margin-right: -15px;">
                        <img src="../assets/img/formation_continue.jpg">
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7;">
                        <div style="text-align: left; padding-left: 30px;">
                            <p style="font-size: x-large; color: #CF0020; font-weight: bold;">{{
                                translate('formation_continue_title') }}
                            </p>
                            <br>
                            <p style="color: black; font-size: medium;">{{ translate('formation_continue_desc') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 40px; padding-top: 0; padding-bottom: 0;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7;">
                        <div style="text-align: left; padding-left: 30px;">
                            <p style="font-size: x-large; color: #CF0020; font-weight: bold;">{{
                                translate('audit_amelioration_title') }}
                            </p>
                            <br>
                            <p style="color: black; font-size: medium;">{{ translate('audit_amelioration_desc') }}</p>
                        </div>
                    </div>
                    <div class="col-md-6" style="text-align: right; margin-left: -15px;">
                        <img src="../assets/img/audit_amelioration.jpg">
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 10px; padding-top: 10px; padding-bottom: 50px;">
            <div class="container" style="margin-top: 20px;">
                <div class="row text-center justify-content-center">
                    <div class="col-12 d-flex">
                        <div class="d-flex flex-column border"
                            style="border-radius: 10px; background-color: #F7F7F7; padding: 30px; ">
                            <div style="text-align: center; flex-grow: 1; font-size: large; color: black;">
                                <span>{{ translate('iso_certif_2018') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img style="max-width: 130px; margin-left: 580px; margin-top: -55px;" src="../assets/img/iso_2018.svg">
            </div>
        </section>
    </section>
</template>





<script>
import MainNav from '@/components/MainNav2.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundImage1 from '../assets/img/iso_header.jpg';
import BackgroundImage2 from '../assets/img/iso_background.jpg';
import BackgroundImage3 from '../assets/img/iso_mobile_back.jpg';





export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            backgroundImage3: BackgroundImage3,
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'CITExpress | ISO';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        this.$nextTick(() => {
      new bootstrap.Carousel(document.querySelector('#headerCarousel1'), {
        ride: 'carousel',
        interval: 3000,
        pause: false
      });
    });

    this.$nextTick(() => {
      new bootstrap.Carousel(document.querySelector('#headerCarousel2'), {
        ride: 'carousel',
        interval: 3000,
        pause: false
      });
    });

    this.$nextTick(() => {
      new bootstrap.Carousel(document.querySelector('#headerCarousel3'), {
        ride: 'carousel',
        interval: 3000,
        pause: false
      });
    });

    this.$nextTick(() => {
      new bootstrap.Carousel(document.querySelector('#headerCarousel4'), {
        ride: 'carousel',
        interval: 3000,
        pause: false
      });
    });
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
    }
}
</script>

<style>
.service-box {
    background-color: rgba(207, 0, 32, 0.5);
    color: white;
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
    height: 100%;
    cursor: pointer;
}

.service-box:hover {
    background-color: rgba(82, 79, 80, 0.5);
    border: 2px solid #efefef;
}

.service-box-mobile {
    border: 1px solid #CF0020;
    margin-top: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 250px;
    border-radius: 20px;
}

.laptop-header header .navbar {
    top: 0;
    left: 0;
}

.custom-btn {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn:hover {
    background-color: #CF0020;
    color: white;
}

.solution-box {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    border: 1px solid white;
    text-align: center;
    color: black;
    padding: 25px;
    height: 100%;
}

.custom-btn2 {
    background-color: rgba(247, 243, 243, 0.1);
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn2:hover {
    background-color: #CF0020;
    color: white;
}

.custom-btn3 {
    background-color: #CF0020;
    border: 1px solid white;
    color: white;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    width: 150px;
}

.custom-btn3:hover {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
}
.review-box {
    background-color: white;
    border-radius: 20px;
    border: 1px solid white;
    color: black;
    padding: 25px;
    margin-left: 15px;
}

.laptop-section {
    display: block;
}

.mobile-section {
    display: none;
}

.ipad-section {
    display: none;
}

@media (max-width: 767px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: block;
    }

    .ipad-section {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: none;
    }

    .ipad-section {
        display: block;
    }
}

.btn-contact {
    background-color: #CF0020;
    border: none;
    margin-right: 20px;
}

.btn-contact:hover {
    background-color: #940B20;
}

.btn-contact-mobile {
    background-color: #CF0020;
    border: none;
}

.btn-contact-mobile:hover {
    background-color: #940B20;
}
</style>
