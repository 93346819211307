<template>
    <div ref="scrollTarget"></div>
    <MainNav />

    <section class="laptop-section">
        <section style="margin-top: 78px; padding-top: 0; padding-bottom: 0;">
            <div class="container-fluid">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6" style="text-align: right; margin-right: -15px;">
                        <img src="../assets/img/technologie2.jpg">
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
                        <div style="text-align: left; padding: 30px;">
                            <p style="font-size: 2.2em; color: black; font-weight: bold;">
                                {{ translate('technologie2_title') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 70px; padding-top: 20px; padding-bottom: 20px; margin-bottom: 130px;">

            <div class="container">
                <div class="row text-center justify-content-center">
                    <div class="col-md-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/technlogie2_icon1.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('technologie2_tech1_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('technologie2_tech1_desc') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/technlogie2_icon2.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('technologie2_tech2_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('technologie2_tech2_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <div class="laptop-header" style="margin-top: 60px;">
            <header class="position-relative">
                <!-- Image -->
                <transition name="image-fade" mode="out-in">
                    <img src="../assets/img/technologie2_header_mobile.jpg" class="d-block w-100"
                        alt="Background Image">
                </transition>

                <!-- Navbar -->
                <MainNav />

            </header>
        </div>

        <section style="padding-top: 0px; padding-bottom: 20px; margin-top: 20px;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12">
                        <p style="color: black; font-size: x-large; font-weight: bold;">{{
                                    translate('technologie2_title') }}</p>
                    </div>
                </div>
            </div>
        </section>


        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 10px;">
            <div class="container" style="margin-top: 10px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-11 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 10px; background-color: #F7F7F7; padding-top: 30px;">
                                        <div>
                                            <img style="max-width: 200px;" src="../assets/img/technlogie2_icon1.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.3em;">
                                                {{ translate('technologie2_tech1_title') }} </p>
                                            <p style="margin-top: 15px">{{ translate('technologie2_tech1_desc') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 10px; margin-bottom: 50px;">
            <div class="container" style="margin-top: 10px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-11 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 10px; background-color: #F7F7F7; padding-top: 30px;">
                                        <div>
                                            <img style="max-width: 200px;" src="../assets/img/technlogie2_icon2.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.3em;">
                                                {{ translate('technologie2_tech2_title') }} </p>
                                            <p style="margin-top: 15px">{{ translate('technologie2_tech2_desc') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="ipad-section">
        <section style="margin-top: 60px; padding-top: 0; padding-bottom: 0;">
            <div class="container-fluid">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6" style="text-align: right; margin-right: -15px;">
                        <img src="../assets/img/technologie2.jpg">
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="background-color: #F7F7F7; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
                        <div style="text-align: left; padding: 30px;">
                            <p style="font-size: xx-large; color: black; font-weight: bold;">
                                {{ translate('technologie2_title') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 40px; padding-top: 20px; padding-bottom: 20px; margin-bottom: 100px;">

            <div class="container">
                <div class="row text-center justify-content-center">
                    <div class="col-md-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/technlogie2_icon1.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('technologie2_tech1_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('technologie2_tech1_desc') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/technlogie2_icon2.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('technologie2_tech2_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('technologie2_tech2_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>





<script>
import MainNav from '@/components/MainNav2.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundImage1 from '../assets/img/technologie_header.jpg';
import BackgroundImage2 from '../assets/img/iso_background.jpg';
import BackgroundImage3 from '../assets/img/iso_mobile_back.jpg';





export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            backgroundImage3: BackgroundImage3,
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'CITExpress | Technologies';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
    }
}
</script>

<style>
.service-box {
    background-color: rgba(207, 0, 32, 0.5);
    color: white;
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
    height: 100%;
    cursor: pointer;
}

.service-box:hover {
    background-color: rgba(82, 79, 80, 0.5);
    border: 2px solid #efefef;
}

.service-box-mobile {
    border: 1px solid #CF0020;
    margin-top: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 250px;
    border-radius: 20px;
}

.laptop-header header .navbar {
    top: 0;
    left: 0;
}

.custom-btn {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn:hover {
    background-color: #CF0020;
    color: white;
}

.solution-box {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    border: 1px solid white;
    text-align: center;
    color: black;
    padding: 25px;
    height: 100%;
}

.custom-btn2 {
    background-color: rgba(247, 243, 243, 0.1);
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn2:hover {
    background-color: #CF0020;
    color: white;
}

.custom-btn3 {
    background-color: #CF0020;
    border: 1px solid white;
    color: white;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    width: 150px;
}

.custom-btn3:hover {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
}
.review-box {
    background-color: white;
    border-radius: 20px;
    border: 1px solid white;
    color: black;
    padding: 25px;
    margin-left: 15px;
}

.laptop-section {
    display: block;
}

.mobile-section {
    display: none;
}

.ipad-section {
    display: none;
}

@media (max-width: 767px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: block;
    }

    .ipad-section {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: none;
    }

    .ipad-section {
        display: block;
    }
}

.btn-contact {
    background-color: #CF0020;
    border: none;
    margin-right: 20px;
}

.btn-contact:hover {
    background-color: #940B20;
}

.btn-contact-mobile {
    background-color: #CF0020;
    border: none;
}

.btn-contact-mobile:hover {
    background-color: #940B20;
}
</style>
