<template>
    <div ref="scrollTarget"></div>

    <section class="laptop-section">
        <section class="background-container" style="padding-bottom: 50px; height: 900px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <MainNav />

            <div class="container"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%;">
                <div class="row gx-5" style="margin-left: 620px;">
                    <div class="col-md-12">
                        <span style="color: white; font-size: 2.2em; font-weight: 600;">
                            {{ translate('about_us_header') }}
                        </span>
                        <br>
                        <span style="color: white; font-size: 1.5em; font-weight: 400;">
                            {{ translate('about_us_sheader') }}
                        </span>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 70px; padding-top: 0; padding-bottom: 0;"
            :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-position': 'center', 'background-size': 'cover' }">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
                        <div style="text-align: left;">
                            <p style="font-size: x-large; color: #CF0020;">{{ translate('about_us_section1_title') }}
                            </p>
                            <br>
                            <p>{{ translate('about_us_section1_contenu') }}</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src="../assets/img/contactuspic.jpg">
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 30px; padding-bottom: 50px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-8 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; background-color: #F7F7F7; padding: 30px; ">
                            <div>
                                <div class="row">
                                    <div class="col-4 d-flex flex-column justify-content-center align-items-center">
                                        <img src="../assets/img/bike&moto.svg">
                                    </div>
                                    <div class="col-8 d-flex flex-column justify-content-center"
                                        style="text-align: left; color: #CF0020; font-weight: 400; font-size: x-large;">
                                        {{ translate('inovation') }}<br>{{ translate('inovation_desc') }}
                                    </div>
                                </div>

                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <span>{{ translate('inovation_text') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 50px;">
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 400; width: 40%;">
                {{ translate('about_us_services_title') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-4 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; width: 90%;">
                            <div>
                                <img src="../assets/img/radio.svg">
                            </div>
                            <div
                                style="text-align: center; flex-grow: 1; padding-left: 40px; padding-right: 40px; padding-top: 30px; padding-bottom: 30px;">
                                <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                translate('about_us_service1_title')
            }}</span>
                                <br>
                                <br>
                                <span>{{ translate('about_us_service1_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; width: 90%;">
                            <div>
                                <img src="../assets/img/sms.svg">
                            </div>
                            <div
                                style="text-align: center; flex-grow: 1; padding-left: 40px; padding-right: 40px; padding-top: 30px; padding-bottom: 30px;">
                                <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                translate('about_us_service2_title')
            }}</span>
                                <br>
                                <br>
                                <span>{{ translate('about_us_service2_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; width: 90%;">
                            <div>
                                <img src="../assets/img/gps.svg">
                            </div>
                            <div
                                style="text-align: center; flex-grow: 1; padding-left: 40px; padding-right: 40px; padding-top: 30px; padding-bottom: 30px;">
                                <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                translate('about_us_service3_title')
            }}</span>
                                <br>
                                <br>
                                <span>{{ translate('about_us_service3_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 50px;">
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 400; width: 40%;">
                {{ translate('inovation_numerique') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-6 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; width: 90%; text-align: left; padding-left: 50px; padding-right: 40px;">
                            <div>
                                <img src="../assets/img/firstWeb.svg">
                                <span style="color: #CF0020; font-weight: 600; font-size: large; margin-left: 10px;">{{
                translate('inovation_numerique1_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 30px; padding-bottom: 30px;">
                                <span>{{ translate('inovation_numerique1_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; width: 90%; text-align: left; padding-left: 50px; padding-right: 40px;">
                            <div>
                                <img src="../assets/img/trackshipment.svg">
                                <span style="color: #CF0020; font-weight: 600; font-size: large; margin-left: 10px;">{{
                translate('inovation_numerique2_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 30px; padding-bottom: 30px;">
                                <span>{{ translate('inovation_numerique2_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 0px; margin-bottom: 0px; height: 600px;"
            :style="{ backgroundImage: 'url(' + backgroundImage4 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="container">
                <div class="row">
                    <div class="col-md-6">

                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center"
                        style="display: flex; flex-direction: column; align-items: left; justify-content: center; height: 600px;">
                        <p style="color: white; font-weight: bold; font-size: x-large;">
                            {{ translate('leadership_title') }}
                        </p>
                        <p style="margin-top: 20px; margin-bottom: 15px; color: white;">
                            {{ translate('leadership_text1') }}
                        </p>
                        <p style="margin-top: 10px; margin-bottom: 15px; color: white;">
                            {{ translate('leadership_text2') }}
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 0px; margin-bottom: 0; height: 300px;"
            :style="{ backgroundImage: 'url(' + backgroundImage3 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-2 d-flex flex-column justify-content-center">
                        <button class="btn btn-primary btn-contact">{{ translate('contacus_button') }}</button>
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center"
                        style="display: flex; flex-direction: column; align-items: left; justify-content: center; height: 300px;">
                        <p style="color: #CF0020;">
                            {{ translate('contactus_paragraphe1') }}
                        </p>
                        <p style="margin-top: 5px; color: #CF0020;">
                            {{ translate('contactus_paragraphe2') }}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <div class="laptop-header">
            <header class="position-relative">
                <!-- Image -->
                <transition name="image-fade" mode="out-in">
                    <img src="../assets/img/contactheader-mobile.jpg" class="d-block w-100" alt="Background Image">
                </transition>

                <!-- Navbar -->
                <MainNav />

                <div class="position-absolute top-0 text-white mobile-prices text-center"
                    style="width: 100%; margin-top: 130px;">
                    <p style="font-weight: bold; font-size: 1.7em; padding-left: 20px; padding-right: 20px;">
                        {{ translate('about_us_header') }}</p>
                    <p
                        style="font-size: 18px; font-weight: 1.3; padding-left: 80px; padding-right: 80px; margin-top: 10px;">
                        {{ translate('about_us_sheader') }}</p>
                </div>
            </header>
        </div>

        <section style="padding-top: 50px; padding-bottom: 0px;"
            :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
                        <div style="text-align: left;">
                            <p style="font-size: x-large; color: #CF0020;">{{ translate('about_us_section1_title') }}
                            </p>
                            <br>
                            <p>{{ translate('about_us_section1_contenu') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 0px;">
            <img src="../assets/img/contactuspic-mobile.jpg" class="d-block w-100">
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 30px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-11 mx-auto">
                                    <div class="border d-flex flex-column" style="border-radius: 10px; background-color: #F7F7F7; padding-top: 30px;">
                                        <div>
                                            <img src="../assets/img/bike&moto.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.5em;">{{ translate('inovation') }} <br> {{ translate('inovation_desc') }}</p>
                                            <p style="margin-top: 15px">{{ translate('inovation_text') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 30px; height: 550px;">
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 500; color: black;">
                {{ translate('about_us_services_title') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel1" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-11 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/radio.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.5em;">{{ translate('about_us_service1_title')}}</p>                                            
                                            <p style="margin-top: 10px;">{{ translate('about_us_service1_contenu') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-11 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/sms.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.5em;">{{ translate('about_us_service2_title')}}</p>                                            
                                            <p style="margin-top: 10px;">{{ translate('about_us_service2_contenu') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-11 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/gps.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.5em;">{{ translate('about_us_service3_title')}}</p>                                            
                                            <p style="margin-top: 10px;">{{ translate('about_us_service3_contenu') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 0px; padding-bottom: 10px; margin-top: -20px;">
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 500; color: black;">
                {{ translate('inovation_numerique') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-11 mx-auto">
                                    <div class="border d-flex flex-column" style="border-radius: 10px; background-color: #F7F7F7; padding-top: 30px;">
                                        <div>
                                            <img src="../assets/img/firstWeb.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.5em;">{{ translate('inovation_numerique1_title') }}</p>
                                            <p style="margin-top: 15px;">{{ translate('inovation_numerique1_contenu') }}</p>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="col-11 mx-auto">
                                    <div class="border d-flex flex-column" style="border-radius: 10px; background-color: #F7F7F7; padding-top: 30px;">
                                        <div>
                                            <img src="../assets/img/trackshipment.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.5em;">{{ translate('inovation_numerique2_title') }}</p>
                                            <p style="margin-top: 15px;">{{ translate('inovation_numerique2_contenu') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="padding-top: 80px; padding-bottom: 80px; margin-top: 20px; height: 700px;"
            :style="{ backgroundImage: 'url(' + backgroundImage5 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">
            <div class="container">
                <p style="color: white; font-size: large; font-weight: 600;">{{ translate('leadership_title') }}</p>
                <br>
                <p style="color: white; font-size: medium; font-weight: 400;">{{ translate('leadership_text1') }}</p>
                <br>
                <br>
                <p style="color: white; font-size: medium; font-weight: 400;">{{ translate('leadership_text2') }}</p>
            </div>
        </section>

        <section style="padding-top: 50px; padding-bottom: 50px; margin-top: 0px;"
            :style="{ backgroundImage: 'url(' + backgroundImage6 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">
            <div class="container" style="text-align: center;">
                <p style="color: #CF0020; font-size: large; font-weight: 400;">{{ translate('contactus_paragraphe1') }}</p>
                <p style="color: #CF0020; font-size: large; font-weight: 400;">{{ translate('contactus_paragraphe2') }}</p>
                <br>
                <button class="btn btn-primary btn-contact-mobile">{{ translate('contacus_button') }}</button>
            </div>
        </section>

    </section>

    <section class="ipad-section">
        <div class="laptop-header">
            <header class="position-relative">
                <!-- Image -->
                <transition name="image-fade" mode="out-in">
                    <img src="../assets/img/contactheader.jpg" class="d-block w-100" alt="Background Image">
                </transition>

                <!-- Navbar -->
                <MainNav />

                <div class="position-absolute bottom-0 text-white mobile-prices text-center" style="width: 100%; margin-bottom: 60px; padding-left: 20px; padding-right: 20px;">
                    <p style="font-weight: bold; font-size: 1.8em;">{{ translate('about_us_header') }}</p>
                    <p style="font-size: 18px; font-weight: 400; padding: 20px; padding-top: 0;">{{ translate('about_us_sheader') }}</p>
                </div>
            </header>
        </div>

        <section style="margin-top: 20px; padding-top: 40px; padding-bottom: 20px;"
            :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
            <div class="container">
                <div class="row justify-content-center text-center">

                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
                        <div style="text-align: left;">
                            <p style="font-size: x-large; color: #CF0020;">{{ translate('about_us_section1_title') }}
                            </p>
                            <br>
                            <p>{{ translate('about_us_section1_contenu') }}</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src="../assets/img/contactuspic.jpg">
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 0px; padding-top: 30px; padding-bottom: 50px;">
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-12 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; background-color: #F7F7F7; padding: 30px; ">
                            <div>
                                <div class="row">
                                    <div class="col-4 d-flex flex-column justify-content-center align-items-center">
                                        <img src="../assets/img/bike&moto.svg">
                                    </div>
                                    <div class="col-8 d-flex flex-column justify-content-center"
                                        style="text-align: left; color: #CF0020; font-weight: 400; font-size: x-large;">
                                        {{ translate('inovation') }}<br>{{ translate('inovation_desc') }}
                                    </div>
                                </div>

                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <span>{{ translate('inovation_text') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 30px; height: 500px;">
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 500; color: black;">
                {{ translate('about_us_services_title') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel2" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/radio.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.5em;">{{ translate('about_us_service1_title')}}</p>                                            
                                            <p style="margin-top: 10px;">{{ translate('about_us_service1_contenu') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/sms.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.5em;">{{ translate('about_us_service2_title')}}</p>                                            
                                            <p style="margin-top: 10px;">{{ translate('about_us_service2_contenu') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 30px; padding-bottom: 20px;">
                                        <div>
                                            <img src="../assets/img/gps.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.5em;">{{ translate('about_us_service3_title')}}</p>                                            
                                            <p style="margin-top: 10px;">{{ translate('about_us_service3_contenu') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 0px; padding-bottom: 10px; margin-top: 0px;">
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 500; color: black;">
                {{ translate('inovation_numerique') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-8 mx-auto">
                                    <div class="border d-flex flex-column" style="border-radius: 10px; background-color: #F7F7F7; padding-top: 30px;">
                                        <div>
                                            <img src="../assets/img/firstWeb.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.5em;">{{ translate('inovation_numerique1_title') }}</p>
                                            <p style="margin-top: 15px;">{{ translate('inovation_numerique1_contenu') }}</p>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="col-8 mx-auto">
                                    <div class="border d-flex flex-column" style="border-radius: 10px; background-color: #F7F7F7; padding-top: 30px;">
                                        <div>
                                            <img src="../assets/img/trackshipment.svg">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <p style="color: #CF0020; font-weight: 500; font-size: 1.5em;">{{ translate('inovation_numerique2_title') }}</p>
                                            <p style="margin-top: 15px;">{{ translate('inovation_numerique2_contenu') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="padding-top: 80px; padding-bottom: 80px; margin-top: 20px; height: 500px;"
            :style="{ backgroundImage: 'url(' + backgroundImage5 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">
            <div class="container">
                <p style="color: white; font-size: x-large; font-weight: 600;">{{ translate('leadership_title') }}</p>
                <br>
                <p style="color: white; font-size: large; font-weight: 400;">{{ translate('leadership_text1') }}</p>
                <br>
                <br>
                <p style="color: white; font-size: large; font-weight: 400;">{{ translate('leadership_text2') }}</p>
            </div>
        </section>

        <section style="padding-top: 50px; padding-bottom: 50px; margin-top: 0px;"
            :style="{ backgroundImage: 'url(' + backgroundImage6 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">
            <div class="container" style="text-align: center;">
                <p style="color: #CF0020; font-size: large; font-weight: 400;">{{ translate('contactus_paragraphe1') }}</p>
                <p style="color: #CF0020; font-size: large; font-weight: 400;">{{ translate('contactus_paragraphe2') }}</p>
                <br>
                <button class="btn btn-primary btn-contact-mobile">{{ translate('contacus_button') }}</button>
            </div>
        </section>
        
    </section>
</template>





<script>
import MainNav from '@/components/MainNav.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundImage1 from '../assets/img/contactheader.jpg';
import BackgroundImage2 from '../assets/img/backgroundred2.jpg';
import BackgroundImage3 from '../assets/img/contactusback.jpg';
import BackgroundImage4 from '../assets/img/backgroundcontac.jpg';
import BackgroundImage5 from '../assets/img/backgroundcontac-mobile.jpg';
import BackgroundImage6 from '../assets/img/contactusback-mobile.jpg';




export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            backgroundImage3: BackgroundImage3,
            backgroundImage4: BackgroundImage4,
            backgroundImage5: BackgroundImage5,
            backgroundImage6: BackgroundImage6,

        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'CITExpress | About us';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        this.$nextTick(() => {
      new bootstrap.Carousel(document.querySelector('#headerCarousel1'), {
        ride: 'carousel',
        interval: 3000,
        pause: false
      });
    });

    this.$nextTick(() => {
      new bootstrap.Carousel(document.querySelector('#headerCarousel2'), {
        ride: 'carousel',
        interval: 3000,
        pause: false
      });
    });
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
    }
}
</script>

<style>
.service-box {
    background-color: rgba(207, 0, 32, 0.5);
    color: white;
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
    height: 100%;
    cursor: pointer;
}

.service-box:hover {
    background-color: rgba(82, 79, 80, 0.5);
    border: 2px solid #efefef;
}

.service-box-mobile {
    border: 1px solid #CF0020;
    margin-top: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 250px;
    border-radius: 20px;
}

.laptop-header header .navbar {
    top: 0;
    left: 0;
}

.custom-btn {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn:hover {
    background-color: #CF0020;
    color: white;
}

.solution-box {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    border: 1px solid white;
    text-align: center;
    color: black;
    padding: 25px;
    height: 100%;
}

.custom-btn2 {
    background-color: rgba(247, 243, 243, 0.1);
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn2:hover {
    background-color: #CF0020;
    color: white;
}

.custom-btn3 {
    background-color: #CF0020;
    border: 1px solid white;
    color: white;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    width: 150px;
}

.custom-btn3:hover {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
}
.review-box {
    background-color: white;
    border-radius: 20px;
    border: 1px solid white;
    color: black;
    padding: 25px;
    margin-left: 15px;
}

.laptop-section {
    display: block;
}

.mobile-section {
    display: none;
}

.ipad-section {
    display: none;
}

@media (max-width: 767px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: block;
    }

    .ipad-section {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: none;
    }

    .ipad-section {
        display: block;
    }
}

.btn-contact {
    background-color: #CF0020;
    border: none;
    margin-right: 20px;
}

.btn-contact:hover {
    background-color: #940B20;
}

.btn-contact-mobile {
    background-color: #CF0020;
    border: none;
}
.btn-contact-mobile:hover {
    background-color: #940B20;
}
</style>
