export default {
    data() {
        return {
            nl: {
                header_text1: "Voorbij het stuur: ",
                header_text2: "Onze chauffeurs, de ambassadeurs van uw merk.",
                header_text3: "Ze belichamen uw waarden op elke weg, zorgen voor een veilige aankomst. Met onberispelijke service garanderen ze tevredenheid en vertrouwen bij elke reis.",
                header_text4: "Betrouwbaarheid in de farmaceutische sector:",
                header_text5: "Gecertificeerde excellentie, ISO 9001 voor nauwkeurige leveringen.",
                header_text6: "Onze beheersing van Belgische gezondheidsnetwerken zorgt voor vlekkeloze distributie, van de farmaceutische sector tot ziekenhuizen, waardoor de standaard van vertrouwen wordt verhoogd.",
                header_text7: "Van Hand tot Hand:",
                header_text8: "Absolute garantie van stiptheid en integriteit.",
                header_text9: "Uw zendingen, kostbaar en breekbaar, worden met de grootste zorg behandeld, wat zorgt voor een perfecte aankomst elke keer.",
                action1_title: "STUUR EEN VERZENDING",
                action2_title: "VRAAG EEN OFFERTE AAN",
                action3_title: "WORD EEN PARTNER",
                why_question1: "Waarom",
                why_question2: "kiezen voor CIT Express?",
                section1_text1: "CIT Express maakt van uw verwachtingen werkelijkheid door haar oplossingen aan te passen aan uw eisen",
                solution1_title: "Leveringssectoren",
                solution1_contenu: "Ontdek onze leveringsdiensten op maat van 14 sectoren, die een breed scala bestrijken van het medische veld tot de luchtvaartindustrie",
                solution2_title: "Onze Vloot",
                solution2_contenu: "Ontdek onze diverse vloot voertuigen, die zorgen voor een betrouwbare en snelle leveringsdienst om aan alle behoeften te voldoen.",
                solution3_title: "Technologie",
                solution3_contenu: "Wij combineren operationele uitmuntendheid met geavanceerde technologieën om een dienst te leveren die de verwachtingen van onze klanten overtreft",
                solution4_title: "ISO 9001-2018",
                solution4_contenu: "Bij CIT Express waarborgt onze naleving van ISO-normen een ongeëvenaarde kwaliteit en efficiëntie, wat zorgt voor totale klanttevredenheid",
                see_more: "Bekijk meer",
                citexpress_name: "CIT EXPRESS",
                citexpress_name_description: "toonaangevend in België sinds 1995",
                citexpress_presentation: "CIT Express, toonaangevend in België sinds 1995, biedt op maat gemaakte logistieke oplossingen om aan uw verzendbehoeften te voldoen, of u nu een individu bent, een e-commerce bedrijf, een klein bedrijf of een multinational...",
                satisfied_clients: "Duizenden tevreden klanten",
                satisfied_clients_description: "Velen van hen, we hebben hen gevraagd hoe tevreden ze waren met onze diensten. Hier zijn hun uitspraken.",
                give_review: "GEEF EEN BEOORDELING",
                user_name1: "Marc B. B2B",
                user_review1: "Vroeger was het volgen van zendingen ingewikkeld met slechts één vervoerder. Vandaag, dankzij CIT Express die al onze vervoerders beheert, verloopt alles soepel en efficiënt.",
                user_name2: "Amina R. B2B",
                user_review2: "Een vervoerder vinden die aan onze eisen voldoet is bijna onmogelijk, CIT Express is daarin geslaagd.",
                user_name3: "Alan D. C2C",
                user_review3: "In een delicate situatie zorgde CIT Express voor snelle en veilige bezorging van belangrijke documenten, waardoor ik werd gered. Een professionele service die ik aanbeveel.",
                user_name4: "Jean-Michel L. B2B",
                user_review4: "Samenwerken met verschillende vervoerders houdt in dat er veel contacten en verschillende facturen zijn. Met CIT Express besparen we waardevolle tijd door deze processen te consolideren.",

                // Navbar
                home: "Home",
                services: "Diensten",
                track_shipment: "Zending Volgen",
                contact_us: "Neem Contact Op",
                my_cit: "Mijn CIT",

                login_text1: "Klaar om te verzenden?",
                login_text2: "Meld u nu aan of registreer",
                form_header: "Aanmelden:",
                email_placeholder: "E-mailadres",
                password_placeholder: "Wachtwoord",
                login: "INLOGGEN",
                not_yet_subscribed: "Nog niet geabonneerd?",
                subscribe: "REGISTREER",

                // Onze Vloot
                notre_flotte_header: "Onze Vloot: Divers, Modern en Ecologisch",
                notre_flotte_desc: "Bij CIT Express is onze vloot van voertuigen het hart van onze mogelijkheid om snelle en betrouwbare bezorgdiensten te bieden. We begrijpen dat elke bezorging uniek is, daarom bestaat onze vloot uit een verscheidenheid aan voertuigen die zijn afgestemd op alle behoeften.",

                notre_flotte_title1: "Elektrische Voertuigen",
                notre_flotte_title1_soustitle1: "Elektrische Scooters",
                notre_flotte_title1_soustitle1_desc: "Perfect voor snelle navigatie in stedelijke gebieden, zijn onze elektrische scooters zowel milieuvriendelijk als efficiënt.",
                notre_flotte_title1_soustitle2: "Elektrische Fietsen",
                notre_flotte_title1_soustitle2_desc: "Ideaal voor lokale bezorgingen, combineren ze de wendbaarheid van traditionele fietsen met ondersteunde kracht, waardoor snelle emissievrije bezorgingen mogelijk zijn.",
                notre_flotte_title2: "Motorvoertuigen",
                notre_flotte_title2_soustitle1: "Bestelwagens",
                notre_flotte_title2_soustitle1_desc: "Onze bestelwagens zijn ideaal voor grotere of meerdere bezorgingen, ze bieden voldoende ruimte en zijn toch wendbaar in de stad.",
                notre_flotte_title2_soustitle2: "Bezorgtrucks",
                notre_flotte_title2_soustitle2_desc: "Voor grotere ladingen of gespecialiseerde bezorgingen zijn onze trucks uitgerust om een breed scala aan goederen veilig te transporteren.",

                notre_flotte_title3: "Gespecialiseerde Voertuigen",
                notre_flotte_title3_soustitle1: "Koelwagens",
                notre_flotte_title3_soustitle1_desc: "Deze voertuigen zijn essentieel voor het transport van producten die temperatuurcontrole vereisen, zoals bepaalde medicijnen of gevoelige technologische apparatuur.",
                notre_flotte_title3_soustitle2: "Voertuigen voor Hoog Beveiligde Bezorging",
                notre_flotte_title3_soustitle2_desc: "Gebruikt voor het transport van documenten of waardevolle voorwerpen die verhoogde beveiliging vereisen, zijn deze voertuigen uitgerust met geavanceerde beveiligingssystemen.",

                notre_flotte_title4: "Toewijding aan Duurzaamheid: Overgang naar Elektrisch",
                notre_flotte_title4_desc1: "We zijn toegewijd aan een geleidelijke overgang naar een volledig elektrische vloot, waardoor onze ecologische voetafdruk wordt verkleind en bijgedragen wordt aan een duurzamere toekomst.",
                notre_flotte_title4_desc2: "Onze vloot wordt regelmatig onderhouden en bijgewerkt om de beste prestaties en betrouwbaarheid te garanderen. Bij CIT Express zijn we trots op onze mogelijkheid om aan alle bezorgbehoeften te voldoen terwijl we ons blijven inzetten voor milieuverantwoordelijkheid en innovatie.",

                about_us_header: "OVER ONS",
                about_us_sheader: "Pioniers van Innovatieve Stedelijke Bezorging",
                about_us_section1_title: "Oorsprong in Brussel, 1996",
                about_us_section1_contenu: "Het avontuur van CIT Express begon in 1996 in Brussel, een stad waar innovatie samensmelt met een rijke culturele erfgoed. Geconfronteerd met stedelijke logistieke uitdagingen, zoals files en demonstraties, hadden we een revolutionaire visie: het transformeren van stedelijke bezorging.",
                inovation: "Innovatie:",
                inovation_desc: "Verder dan Scooter- en Fietsbezorging",
                inovation_text: "Ons oorspronkelijke concept onderscheidde zich al snel door het gebruik van scooters en fietsen voor snelle en efficiënte bezorgingen. Maar onze echte doorbraak was onze baanbrekende adoptie van innovatieve technologieën.",
                about_us_services_title: "Pioniers in Communicatietechnologie",
                about_us_service1_title: "Radio Communicatie",
                about_us_service1_contenu: "We waren een van de eersten die radiocommunicatie gebruikten om onze bezorgingen te coördineren, waardoor onze operationele efficiëntie werd verhoogd.",
                about_us_service2_title: "Introductie van SMS",
                about_us_service2_contenu: "Door de vroege adoptie van SMS konden we snellere en directere communicatie met onze teams op het veld mogelijk maken.",
                about_us_service3_title: "GPS Pioniers",
                about_us_service3_contenu: "De integratie van GPS heeft onze mogelijkheid om bezorgingen in realtime te volgen, revolutionair verbeterd, met ongekende transparantie en betrouwbaarheid.",
                inovation_numerique: "Digitale Innovatie",
                inovation_numerique1_title: "1e Online Bestelsite",
                inovation_numerique1_contenu: "We lanceerden een van de eerste online bezorgbestelsites, waardoor onze klanten gebruiksgemak en verhoogde efficiëntie kregen.",
                inovation_numerique2_title: "Online Volgen en Facturering",
                inovation_numerique2_contenu: "Door online bestelvolging en elektronische facturering in te voeren, hebben we niet alleen het proces voor onze klanten vereenvoudigd, maar ook onze ecologische voetafdruk verkleind.",
                leadership_title: "Een Erfgoed van Leiderschap en Innovatie",
                leadership_text1: "Deze innovaties hebben niet alleen CIT Express gevestigd als een leider in stedelijke bezorging, maar hebben ook de weg geëffend voor technologieën die veel grote spelers in de branche vandaag de dag gebruiken. We zijn er trots op pioniers te zijn geweest, altijd op zoek naar nieuwe manieren om onze diensten te verbeteren en te anticiperen op de behoeften van onze klanten.",
                leadership_text2: "Bij CIT Express is onze geschiedenis er een van constante innovatie en toewijding aan uitmuntendheid. We blijven evolueren, altijd op zoek naar nieuwe manieren om onze klanten efficiënt en betrouwbaar van dienst te zijn.",
                contactus_paragraphe1: "Laat ons u laten zien hoe CIT Express efficiënt en betrouwbaar aan uw stedelijke bezorgvereisten kan voldoen.",
                contactus_paragraphe2: "Neem vandaag nog contact met ons op voor een uitzonderlijke samenwerking.",
                contacus_button: "Neem Contact Op",

                iso_title: "ISO 9001-2018",
                iso_title_s1: "Kwaliteit",
                iso_title_s2: "Management",
                iso_title_s3: "Systemen",
                norme_iso: "ISO-normen:",
                norme_iso_desc: "De Basis van Onze Uitmuntendheid in Gespecialiseerde Bezorging",
                iso_icon_title: "Toewijding aan ISO Kwaliteitsnormen",
                iso_icon_desc: "Bij CIT Express staat naleving van ISO-normen centraal in onze toewijding aan ongeëvenaarde kwaliteit en efficiëntie. Onze processen zijn rigoureus ontworpen om te voldoen aan en deze internationale normen te overtreffen, wat totale klanttevredenheid garandeert.",
                iso_app_title: "Toepassing van ISO-normen in Onze Gespecialiseerde Diensten",
                iso_app1_title: "ISO 9001 voor Kwaliteitsmanagement",
                iso_app1_contenu: "Al onze processen, van operationeel management tot bezorgdiensten, voldoen aan ISO 9001, wat een consistente en betrouwbare kwaliteit garandeert.",
                iso_app2_title: "ISO 14001 voor Milieu",
                iso_app2_contenu: "We zijn toegewijd aan het minimaliseren van onze ecologische impact door praktijken die voldoen aan ISO 14001, waarbij onze ecologische verantwoordelijkheid wordt benadrukt.",
                iso_app3_title: "ISO 45001 voor Gezondheid en Veiligheid",
                iso_app3_contenu: "De veiligheid en het welzijn van ons team en onze klanten blijven een topprioriteit, voortdurend geleid door ISO 45001-normen en de inzet van allen.",
                iso_norme_title: "Sector Specialisatie en Gerelateerde Normen",
                iso_norme1_title: "Farmaceutische Sector",
                iso_norme1_contenu: "We houden ons aan strenge normen voor het hanteren en transporteren van farmaceutische producten, waaronder Good Distribution Practices (GDP) en specifieke richtlijnen voor de koudeketen.",
                iso_norme2_title: "Elektronica- en Technologiesector",
                iso_norme2_contenu: "Voor het transport van gevoelige elektronische producten passen we specifieke normen toe die bescherming bieden tegen elektrostatische ontlading en mechanische schade.",
                iso_norme3_title: "Juridische en Financiële Sector",
                iso_norme3_contenu: "We houden ons aan strikte privacy- en beveiligingsprotocollen om de veiligheid en vertrouwelijkheid van gevoelige documenten te waarborgen.",
                iso_norme4_title: "Ruimtevaartsector",
                iso_norme4_contenu: "De specifieke eisen van deze sector worden vervuld, inclusief het transport van kritische materialen en componenten, in overeenstemming met de kwaliteits- en veiligheidsnormen voor de lucht- en ruimtevaart.",
                formation_continue_title: "Continue Training en Bewustwording",
                formation_continue_desc: "Ons personeel ontvangt voortdurende training over ISO-normen en sector-specifieke eisen. Deze aanpak zorgt voor foutloze expertise en naleving in al onze activiteiten.",
                audit_amelioration_title: "Audit en Continue Verbetering",
                audit_amelioration_desc: "Regelmatige audits worden uitgevoerd om de naleving van ISO-normen te verifiëren en verbeteringsmogelijkheden te identificeren. Deze continue verbeteringsbenadering versterkt onze toewijding aan uitmuntendheid.",
                iso_certif_2018: "Door deze normen te integreren in ons operationele model, onderscheidt CIT Express zich als een betrouwbare en gespecialiseerde bezorgdienstverlener, toegewijd aan de hoogste normen voor elk van de sectoren die we bedienen.",

                secteurs_header: "Bezorgsectoren",
                secteurs_header_desc1: "Ontdek onze bezorgdiensten op maat voor 14 sectoren, van medisch tot lucht- en ruimtevaart.",
                secteurs_header_desc2: "Kies uw domein uit het dropdownmenu en ontdek hoe onze expertise veiligheid en snelheid garandeert voor uw zendingen.",
                secteurs_sheader: "CIT Express, 30 jaar ervaring, uw betrouwbare partner voor maatwerk bezorgingen!",
                secteur1_title: "Medische en Farmaceutische Sector",
                secteur1_contenu: "Dringende leveringen van medicijnen, vaccins, biologische monsters, medische apparatuur. Temperatuurgecontroleerd transport voor gevoelige items.",
                secteur2_title: "Technologie en Elektronica",
                secteur2_contenu: "Levering van gevoelige elektronische componenten, computerapparatuur. Noodzaak voor zorgvuldige behandeling en bescherming tegen schokken.",
                secteur3_title: "Detailhandel en E-commerce",
                secteur3_contenu: "Optimaliseer uw online bedrijf met snelle levering, efficiënt retourbeheer en voorraadsynchronisatie via API's. Zorg voor responsieve klantenservice.",
                secteur4_title: "Automobielindustrie",
                secteur4_contenu: "Dringend transport van reserveonderdelen, componenten voor productie. Just-in-time leveringen om productiecontinuïteit te handhaven.",
                secteur5_title: "Onderzoek en Ontwikkeling",
                secteur5_contenu: "Service zorgt voor veilig transport van uw onderzoeksmateriaal, wetenschappelijke monsters en prototypes, met bijzondere aandacht voor nauwkeurige temperatuurregeling.",
                secteur6_title: "Advies en Training",
                secteur6_contenu: "Snelle levering van belangrijke documenten, presentatie- en trainingsmateriaal, apparatuur voor audits of evaluaties. Belang van snelheid en betrouwbaarheid.",

                sous_secteur1_title: "Voedingsmiddelenindustrie",
                sous_secteur1_contenu: "Transport van bederfelijke goederen, cateringbenodigdheden, dringende leveringen voor supermarkten. Temperatuur- en versheidseisen.",
                sous_secteur2_title: "Bouw- en Ingenieurswezen",
                sous_secteur2_contenu: "Voor het transport van gevoelige elektronische producten passen we specifieke normen toe die bescherming bieden tegen elektrostatische ontlading en mechanische schade.",
                sous_secteur3_title: "Juridisch en Financieel",
                sous_secteur3_contenu: "We houden ons aan strikte privacy- en beveiligingsprotocollen om de veiligheid en vertrouwelijkheid van gevoelige documenten te waarborgen.",
                sous_secteur4_title: "Lucht- en Ruimtevaartsector",
                sous_secteur4_contenu: "De specifieke eisen van deze sector worden vervuld, inclusief het transport van kritische materialen en componenten, in overeenstemming met lucht- en ruimtevaartkwaliteits- en veiligheidsnormen.",
                sous_secteur5_title: "Openbare en Overheidssector",
                sous_secteur5_contenu: "We volgen strikte normen voor het hanteren en transporteren van farmaceutische producten, inclusief Good Distribution Practices (GDP) en specifieke richtlijnen voor de koudeketen.",
                sous_secteur6_title: "Mode en Textiel",
                sous_secteur6_contenu: "Voor het transport van gevoelige elektronische producten passen we specifieke normen toe die bescherming bieden tegen elektrostatische ontlading en mechanische schade.",
                sous_secteur7_title: "Evenementen en Entertainment",
                sous_secteur7_contenu: "We houden ons aan strikte privacy- en beveiligingsprotocollen om de veiligheid en vertrouwelijkheid van gevoelige documenten te waarborgen.",
                sous_secteur8_title: "Kunst en Antiek",
                sous_secteur8_contenu: "De specifieke eisen van deze sector worden vervuld, inclusief het transport van kritische materialen en componenten, in overeenstemming met lucht- en ruimtevaartkwaliteits- en veiligheidsnormen.",

                technologie_header: "Technologie tot Uw Dienst voor een Naadloze, Gemakkelijke en Intuïtieve Ervaring",
                technologie_sheader: "Bij CIT Express combineren we operationele uitmuntendheid met geavanceerde technologieën om een bezorgdienst aan te bieden die niet alleen voldoet, maar de verwachtingen van onze klanten overtreft.",
                technologie1_title: "Extra Technologische Kenmerken",
                technologie2_title: "Toegankelijke en Transparante Bezorgtracking",
                technologie3_title: "Intuïtieve en Efficiënte Boekhouding",
                technologie_desc: "Technologische innovatie bij CIT Express is gewijd aan het continu verbeteren van de ervaring van onze klanten, waarbij logistieke oplossingen worden geboden die niet alleen geavanceerd zijn, maar ook perfect aansluiten bij hun operationele en financiële behoeften.",

                technologie1_tech1_title: "Vereenvoudigde Bestelinterface",
                technologie1_tech1_desc: "Ons online platform is ontworpen voor een intuïtieve gebruikerservaring, waardoor snelle en moeiteloze bestellingen mogelijk zijn.",
                technologie1_tech2_title: "Automatische Meldingen en Waarschuwingen",
                technologie1_tech2_desc: "Blijf op de hoogte in elke fase van uw bezorging met automatische meldingen en updates.",
                technologie1_tech3_title: "Integratie met Bedrijfssystemen",
                technologie1_tech3_desc: "Ons platform integreert naadloos met ERP- en CRM-systemen voor gecentraliseerd en efficiënt orderbeheer.",
                technologie1_tech4_title: "Veilige Betalingsopties",
                technologie1_tech4_desc: "We bieden verschillende veilige betalingsopties om aan de behoeften van onze klanten te voldoen.",
                technologie1_tech5_title: "24/7 Klantenondersteuning",
                technologie1_tech5_desc: "Ons klantenserviceteam is 24/7 beschikbaar om eventuele vragen te beantwoorden en problemen snel op te lossen.",
                technologie2_tech1_title: "Realtime Geolocatie",
                technologie2_tech1_desc: "Onze klanten en pakketontvangers kunnen de voortgang van hun zendingen in realtime volgen via ons geavanceerde geolocatiesysteem.",
                technologie2_tech2_title: "Interactief Trackingprogramma",
                technologie2_tech2_desc: "Ontvangers zijn geïntegreerd in ons trackingprogramma, waardoor ze gemakkelijk de status en locatie van hun zending kunnen controleren voor volledige transparantie en gemoedsrust.",
                technologie3_tech1_title: "Vereiste van Baan- of Inkoopordernummer",
                technologie3_tech1_desc: "Om het werk van uw boekhoudafdeling te stroomlijnen, vereist ons systeem invoer van een baan- of inkoopordernummer (PO) voordat een bestelling wordt geplaatst. Deze praktijk zorgt voor een nauwkeurige kostenallocatie en vereenvoudigd boekhoudbeheer.",
                technologie3_tech2_title: "Aanpasbare en Exporteerbare Boekhoudrapporten",
                technologie3_tech2_desc: "Onze klanten kunnen aangepaste boekhoudrapporten genereren en exporteren, waardoor naadloze integratie met hun eigen financiële systemen mogelijk is.",

                about_us: "Over Ons",
                terms_of_use: "Gebruiksvoorwaarden",
                privacy_policy: "Privacybeleid",
                accessibility_statement: "Toegankelijkheidsverklaring",
                all_rights_resereved: "Alle Rechten Voorbehouden",

                username: "Gebruikersnaam",

                suivant: "Volgende",
                nom_entreprise: "Bedrijfsnaam?",
                secteur_activite: "Bedrijfssector?",
                contact_principale: "Primaire contactpersoon (Naam en functie)?",
                address_email: "E-mailadres?",
                phone_number: "Telefoonnummer",
                repondez_ici: "Antwoord hier...",
                question1: "Wat is de frequentie van uw behoeften op het gebied van levering?",
                question1_choice1: "Dagelijks",
                question1_choice2: "Wekelijks",
                question1_choice3: "Maandelijks",
                question1_choice4: "Anders",
                question2: "Wat is het gemiddelde volume van uw zendingen? (Aantal pakketten of geschat gewicht per zending)",
                question3: "Wat zijn de belangrijkste bestemmingen van uw zendingen?",
                question3_choice1: "Nationaal",
                question3_choice2: "Internationaal",
                question3_choice3: "Beide",
                question4: "Welk type goederen verzendt u?",
                question4_choice1: "Documenten",
                question4_choice2: "Pakketten",
                question4_choice3: "Pallets",
                question4_choice4: "Speciale Goederen",
                question5: "Zijn er specifieke eisen voor uw goederen? (Breekbaar, temperatuurgecontroleerd, gevaarlijk, etc.)",
                question5_choice1: "Ja",
                question5_choice2: "Nee",
                question6: "Naar welke bezorgdiensten bent u op zoek?",
                question6_choice1: "Standaard Levering",
                question6_choice2: "Express",
                question6_choice3: "Zelfde Dag",
                question7: "Heeft u aanvullende diensten nodig? (Verzekering, real-time tracking, levering met handtekening, etc.)",
                question7_choice1: "Ja",
                question7_choice2: "Nee",
                question8: "Heeft u ooit gebruik gemaakt van express leveringsdiensten?",
                question8_choice1: "Ja",
                question8_choice2: "Nee",
                question9: "Wat zijn uw belangrijkste criteria bij het kiezen van een bezorgdienst? (Kosten, betrouwbaarheid, snelheid, klantenservice, etc.)",
                question10: "Heeft u specifieke verwachtingen of zorgen met betrekking tot de bezorgdienst?",
                question11: "Wat is uw geschatte budget voor bezorgdiensten?",
                question12: "Wat zijn uw voorkeuren voor facturering en betaling?",
                thanks_message1: "Bedankt voor het aanvragen van een offerte.",
                thanks_message2: "Een van onze branchedeskundigen verwerkt uw aanvraag. U wordt binnenkort gecontacteerd om uw bezorgbehoeften en onze oplossingen te bespreken.",

                contactus_header: "Neem contact op",
                contactus_sheader1: "Welkom bij CIT Express, experts in snelle levering",
                contactus_sheader2: "Hulp nodig? Neem contact met ons op!",
                contactus_form_input1: "Naam",
                contactus_form_input2: "Bedrijf",
                contactus_form_input3: "Telefoon",
                contactus_form_input4: "E-mail",
                contactus_form_input5: "Onderwerp",
                contactus_form_input6: "Bericht",
                contactus_form_button: "Verzenden",
                contactus_text1: "Dedicated telefoonnummer voor onmiddellijke hulp:",
                contactus_text2: "Ons team is beschikbaar",
                contactus_text3: "Om snel op uw dringende zorgen te reageren.",

                construction_text1: "Pagina in aanbouw",
                construction_text2: "Sorry, maar deze pagina is momenteel in aanbouw.",
                construction_text3: "Kom binnenkort terug om updates te zien.",
                nos_service: "Onze diensten",
                contactus_message: "Bedankt, we zullen binnenkort contact met u opnemen"






            }
        }
    }
}
