import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import NotreFlotte from '../views/NotreFlotte.vue'
import AboutUs from '../views/AboutUs.vue'
import Iso9001 from '@/views/Iso9001.vue'
import SecteurLivraison from '../views/SecteurLivraison.vue'
import Technologies from '../views/Technologies.vue'
import Technologie1 from '../views/Technologie1.vue'
import Technologie2 from '../views/Technologie2.vue'
import Technologie3 from '../views/Technologie3.vue'
import Quote from '../views/Quote.vue'
import ContactUS from '../views/ContactUS.vue'
import Construction from '../views/Construction.vue'
import NotFound from '../views/NotFound.vue'



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/notre-flotte',
    name: 'NotreFlotte',
    component: NotreFlotte
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/iso-9001-2018',
    name: 'Iso9001',
    component: Iso9001
  },
  {
    path: '/secteurs-livraison',
    name: 'SecteurLivraison',
    component: SecteurLivraison
  },
  {
    path: '/technologies',
    name: 'Technologies',
    component: Technologies
  },
  {
    path: '/technologie1',
    name: 'Technologie1',
    component: Technologie1
  },
  {
    path: '/technologie2',
    name: 'Technologie2',
    component: Technologie2
  },
  {
    path: '/technologie3',
    name: 'Technologie3',
    component: Technologie3
  },
  {
    path: '/quote',
    name: 'Quote',
    component: Quote
  },
  {
    path: '/contact',
    name: 'ContactUS',
    component: ContactUS
  },
  {
    path: '/construction',
    name: 'Construction',
    component: Construction
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
